.container {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  padding: 14px;
  color: #333;
  width: 90%;
  max-width: 380px;
  margin: 0 auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #2b2b2a;
  border-radius: 20px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;

  z-index: 9999;
  background-color: #444242;
}

.title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

.subtitle {
  font-size: 14px;
  text-align: center;
  color: #fff;
  margin: 0;
}

.reward-close-button {
  position: absolute;
  top: 6px;
  background: none;
  border: none;
  cursor: pointer;
  right: 16px;
  font-size: 24px;
  color: #fff;
}

.rewards-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 8px 6px;
  margin: 8px 0;
  width: 100%;
}

.reward {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2px 0px;
  border-radius: 8px;
  background-color: #f0f0f0;
  width: 100%;
  text-align: center;
  gap: 6px;
}

.reward.active {
  background-color: rgb(205, 235, 69);
}

.reward.inactive {
  background-color: #e0e0e0; /* Gray color for inactive rewards */
}

.reward.inactive > .coin-image {
  filter: grayscale(100%);
}

.reward.today {
  background-color: #4e3e28; /* Gray color for inactive rewards */
  border: 1px solid #dfaf25;
}

.reward.today > .amount-text {
  color: #dfaf25;
}

.reward.today > .day-text {
  color: #4e3e28;
  background: #dfaf25;
}

.reward.active > .day-text {
  color: rgb(205, 235, 69);
  background: #3d4518;
}

.day-text {
  font-size: 12px;
  color: #000;
  background: #c3c0bf;
  width: 90%;
  border-radius: 6px;
  margin-top: 1px;
  font-family: "myriadpro-bold";
}

.coin-image {
  width: 30px;
  height: 30px;
}

.amount-text {
  font-size: 14px;
  color: #000;
  font-weight: bold;
}

.button {
  margin-top: 10px;
  margin-bottom: 20px;
  background-color: #cdeb45;
  padding: 10px 0;
  width: 108px;
  border-radius: 40px;
  border: none;
  color: #3d4518;
  font-size: 20px;
  cursor: pointer;
  font-family: "myriadpro-bold";
}

.button:disabled {
  background-color: #ccc;
}

/* @media (max-width: 768px) {
  .rewards-grid {
    grid-template-columns: repeat(6, 1fr);
    gap: 5px;
  }

  .button {
    width: 100%;
    padding: 15px;
  }
}

@media (max-width: 480px) {
  .title {
    font-size: 20px;
  }

  .subtitle {
    font-size: 14px;
  }

  .rewards-grid {
    grid-template-columns: repeat(6, 1fr);
    gap: 5px;
  }

  .button {
    font-size: 14px;
    padding: 10px;
  }
} */

.claim-button {
  margin-bottom: 40px;
}

.reward.inactive .day-text,
.reward.inactive .amount-text {
  color: #999;
}

.reward-image-container {
  margin: auto;
  margin-top: 20px;
}

.reward-title {
  color: #cdeb45;
  text-align: center;
  width: 90%;
  font-size: 24px;
  font-weight: bold;
  margin: auto;
  line-height: normal;
}

.cute-character {
  width: 140px;
  height: auto;
}
