/* General container styling */
.casino-container {
  background-color: black;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 20px;
  margin-top: 20px; */
  color: white;
  overflow-y: auto;
}

.casino-container::-webkit-scrollbar {
  display: none;
}

/* Header styling */
.casino-title {
  font-size: 50px;
  font-weight: bold;
  color: #cdeb45;
  text-align: center;
}

.casino-subtitle {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin: 10px 0 20px;
}

.casino-subtitle-green {
  font-size: 16px;
  font-weight: bold;
  color: #cdeb45;
  text-align: center;
  margin-top: 20px;
}

/* Timer styling */
.casino-timer {
  font-size: 48px;
  font-weight: bold;
}

/* Image styling */
.casino-image-container {
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
}

.casino-image {
  width: 256px;
  height: 256px;
}

/* Content and button styling */
.casino-content {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.go-button {
  padding-top: 8px;
  width: 70px;
  height: 70px;
  color: black;
  background-color: #cdeb45;
  font-weight: bold;
  font-size: 32px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: transform 0.2s;
}

.go-button:hover {
  transform: scale(1.1);
}

.casino-thanks {
  width: 80%;
  text-align: center;
  margin-top: 40px;
  font-size: 18px;
  font-family: "myriadpro-semibold";
}

/* .game-list {
  height: 400px;
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: red;
} */



.game-button {
  color: black;
  background-color: #cdeb45;
  border: none;
  border-radius: 20px;
  padding: 8px;
  margin-left: 10px;
  margin-top: 10px;
  font-size: 14px;
  text-align: center;
}

.button-container {
  display: flex;
  gap: 10px;
  /* Space between buttons */
}

.button-container {
  display: flex;
  align-items: center;
  gap: 10px;
  /* Space between buttons */
  background-color: #1a1a2e;
  /* Dark background */
  padding: 10px;
  border-radius: 30px;
  /* Smooth rounded edges */
}

.wheel-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 32px;
  background: #1a1a1a;
  border-radius: 8px;
  max-width: 500px;
  margin: 0 auto;
}

.points-display {
  color: white;
  font-size: 20px;
  margin-bottom: 16px;
}

.wheel-wrapper {
  position: relative;
  width: 300px;
  height: 300px;
}

.wheel {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 8px solid #FFD700;
  background: conic-gradient(from 0deg,
      #9966CC 0deg,
      #9966CC 45deg,
      #2D2D2D 45deg,
      #2D2D2D 90deg,
      #9966CC 90deg,
      #9966CC 135deg,
      #2D2D2D 135deg,
      #2D2D2D 180deg,
      #9966CC 180deg,
      #9966CC 225deg,
      #2D2D2D 225deg,
      #2D2D2D 270deg,
      #9966CC 270deg,
      #9966CC 315deg,
      #2D2D2D 315deg,
      #2D2D2D 360deg);
  display: flex;
  align-items: center;
  justify-content: center;
}

.segment {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.segment-content {
  position: absolute;
  top: 0;
  left: 50%;
  transform-origin: bottom;
  height: 50%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 1px;
}

.segment-text {
  color: white;
  font-weight: bold;
  font-size: 14px;
  white-space: nowrap;
  transform: translateX(95%) translateY(50px);
  rotate: 67deg;
}

.wheel::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: conic-gradient(from 0deg,
      #000 0deg,
      transparent 1deg,
      transparent 44deg,
      #000 45deg,
      #000 46deg,
      transparent 47deg,
      transparent 89deg,
      #000 90deg,
      #000 91deg,
      transparent 92deg,
      transparent 134deg,
      #000 135deg,
      #000 136deg,
      transparent 137deg,
      transparent 179deg,
      #000 180deg,
      #000 181deg,
      transparent 182deg,
      transparent 224deg,
      #000 225deg,
      #000 226deg,
      transparent 227deg,
      transparent 269deg,
      #000 270deg,
      #000 271deg,
      transparent 272deg,
      transparent 314deg,
      #000 315deg,
      #000 316deg,
      transparent 317deg,
      transparent 359deg,
      #000 360deg);
  opacity: 0.1;
}

.center-pointer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 48px;
  height: 48px;
  background: #FFD700;
  border-radius: 50%;
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
}

.center-pointer::after {
  content: '';
  width: 32px;
  height: 32px;
  background: #FFB700;
  border-radius: 50%;
}

.top-pointer {
  position: absolute;
  top: -4px;
  left: 50%;
  transform: translateX(-50%);
  width: 24px;
  height: 24px;
  background: #FFB700;
  clip-path: polygon(50% 100%, 0% 0%, 100% 0%);
  z-index: 10;
}

.spin-button {
  padding: 12px 24px;
  border-radius: 9999px;
  color: white;
  font-weight: bold;
  background: #9333EA;
  cursor: pointer;
  border: none;
}

.spin-button:hover {
  background: #7E22CE;
}

.spin-button.disabled {
  background: #4B5563;
  cursor: not-allowed;
}

.collect-container {
  text-align: center;
  margin-top: 16px;
}

.win-text {
  color: white;
  font-size: 24px;
  margin-bottom: 8px;
}

.collect-button {
  padding: 12px 24px;
  background: #22C55E;
  color: white;
  font-weight: bold;
  border-radius: 9999px;
  border: none;
  cursor: pointer;
}

.collect-button:hover {
  background: #16A34A;
}

.button-container {
  display: flex;
  align-items: center;
  gap: 10px;
  /* Space between buttons */
  background-color: #1a1a2e;
  /* Dark background */
  padding: 10px;
  border-radius: 30px;
  /* Smooth rounded edges */
}

.spin-button {
  background-color: #5a4fcf;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 20px;
  font-size: 14px;
  cursor: pointer;
}

.get-more-button {
  background-color: #2d2a48;
  color: #a48df0;
  padding: 8px 16px;
  border: 1px solid #5a4fcf;
  border-radius: 20px;
  font-size: 14px;
  cursor: pointer;
}

.close-button {
  background: none;
  border: none;
  color: white !important;
  font-size: 20px;
  cursor: pointer;
  position: fixed !important;
  /* top: 10px !important; */
  right: 350px !important;
  /* Pushes the close button to the right */
}




/* Reward popup */
/* RewardPopup.css */
.popup-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  /* Adjust based on your design */
  max-width: 400px;
  /* Prevents it from becoming too large */
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 20px;
  border-radius: 10px;
  /* position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh; */
  /* background: rgba(0, 0, 0, 0.7); */
  /* display: flex;
  align-items: center;
  justify-content: center; */
  /* z-index: 1000; */
}

.popup-card {
  background: #1a1a2e;
  color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  width: 300px;
  box-shadow: 0px 0px 15px rgba(255, 215, 0, 0.7);
  position: relative;
  animation: popUp 0.3s ease-in-out;
}

@keyframes popUp {
  from {
    transform: scale(0.8);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}

.coins-animation {
  font-size: 2rem;
  margin-bottom: 10px;
  animation: bounce 1s infinite;
}

@keyframes bounce {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-5px);
  }
}

.awesome-btn {
  background: #8e44ad;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}

.awesome-btn:hover {
  background: #6c3483;
}

.game-list {
  /* margin-top: 50px; */
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px;

  overflow-x: auto;
  white-space: nowrap;

  width: 350px;
  max-width: 350px;
  height: 300px;
  min-height: 650px;

  background-color: #000;
  /* border: 2px solid gray; */
  /* border-radius: 10px; */
}

.game-title {
  min-width: 100%;
  height: 200px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
  overflow: hidden;
  border: 1px solid white;
  border-radius: 10px;
}

.game-image-container {
  min-width: 100%;
  height: 85%;
}

.gamesButton {
  min-width: 100%;
  min-height: 15%;
  background-color: #cdeb45;
  margin: 0;
  color: black;
}

.game-image {
  height: 100%;
  width: 100%;
  height: fit-content;
}

.notshow {
  display: none;
}