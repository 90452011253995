.tasks-container {
  width: 100%;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.back-button {
  background: none;
  border: none;
  cursor: pointer;
}

.task-list-title {
  flex-grow: 1;
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  color: #cdeb45;
  margin-top: 20px;
}

.tasks-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/*   .task-image-container {
    margin-bottom: 20px;
  }
  
  .task-cute-characters {
    width: 133px;
    height: auto;
  }
   */
.task-image-container {
  margin-bottom: 5px;
}

.task-cute-character {
  width: 160px;
  height: 160px;
}

.task-list-heading {
  color: rgb(205, 235, 69);
  text-align: start;
  padding: 10px 0px;
}

.task-instruction-text {
  text-align: center;
  color: white;
  margin-bottom: 0px;
  margin-top: 14px;
  font-weight: 500;
  font-size: 14px;
}

.tasks-list {
  width: 100%;
  border-radius: 15px;
  padding: 30px;
  max-width: 400px;
}

.tasks-list h2 {
  color: #745e50;
  margin-bottom: 15px;
}

.task-item {
  background-color: #5b5b5b;
  border-radius: 10px;
  padding: 8px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.task-info {
  display: flex;
  align-items: center;
}

.task-icon {
  font-size: 24px;
  margin-right: 10px;
}

.task-title {
  color: white;
  font-size: 16px;
}
.task-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.task-reward {
  display: flex;
  align-items: center;
}

.task-coins {
  color: white;
  font-family: "myriadpro-bold";
}

.start-button {
  background-color: #282828;
  color: white;
  border: none;
  border-radius: 20px;
  min-width: 80px;
  padding: 8px;
  margin: 0;
  font-size: 14px;
  text-align: center;
}

.claim {
  background-color: #cdeb45;
  color: black;
  font-family: "myriadpro-bold";
}

.image-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  padding: 5px 15px;
  border: none;
  border-radius: 5px;
  background-color: #f4f4f4;
  cursor: pointer;
  width: 70px;
  gap: 8px; /* space between image and text */
}
.image-button:hover {
  background-color: #ddd;
}
.check-button {
  width: 20px; /* Adjust size */
  height: 20px;
}

.loading-button {
  background-color: #cdeb45;
  color: black;
  border-radius: 15px;
  border: none;
  width: 70px;
}


/* Loader Background */
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #000; /* Pure Black */
}

/* Loader Animation */
.loader {
  width: 75px;
  height: 75px;
  border: 5px solid #cdeb45;
  border-top: 5px solid  #5b5b5b; /* White Color */
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

/* Keyframes for Rotation */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
