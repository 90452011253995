body {
    background-color: #121212;
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

.slot-machine {
    margin: auto;
    width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.slot-container {
    background-color: #000;
    border: 4px solid #333;
    border-radius: 10px;
    padding: 15px;
    position: relative;
    margin-bottom: 15px;
}

.reels {
    display: flex;
    justify-content: space-between;
    gap: 2px;
    background-color: #000;
    padding: 5px;
    border-radius: 5px;
}

.reel-container {
    width: 100px;
    height: 180px;
    background-color: #000;
    overflow: hidden;
    position: relative;
    border-radius: 2px;
}

.reel-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 60px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    z-index: 10;
    pointer-events: none;
}

.reel-container::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 60px;
    background: linear-gradient(to top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    z-index: 10;
    pointer-events: none;
}

.reel-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: transform 0.1s linear;
    transform: translateY(-700px);
}

.reel-wrapper.spinning {
    transition: none;
    animation: spinReel 0.2s linear infinite;
}

@keyframes spinReel {
    0% {
        transform: translateY(-700px);
    }

    100% {
        transform: translateY(-460px);
        /* Adjusted for smooth looping */
    }
}

.reel-symbol {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 60px;
    font-size: 36px;
    font-weight: bold;
    text-shadow: 0 0 5px rgba(255, 255, 255, 0.7);
}

.nav-arrows {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    display: flex;
    justify-content: space-between;
    padding: 0 5px;
    pointer-events: none;
}

.arrow {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #4CEF88;
    font-size: 18px;
    font-weight: bold;
}

.win-message {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.8);
    color: #FFD700;
    padding: 15px 25px;
    border-radius: 10px;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    box-shadow: 0 0 20px rgba(255, 215, 0, 0.5);
    z-index: 100;
    border: 2px solid #FFD700;
    animation: pulse 1.5s infinite;
}

.win-message.jackpot {
    background-color: rgba(255, 0, 0, 0.8);
    color: #FFD700;
    font-size: 30px;
    box-shadow: 0 0 30px rgba(255, 0, 0, 0.8);
    animation: jackpotPulse 0.5s infinite;
}

.win-amount {
    margin-top: 10px;
    font-size: 28px;
}

@keyframes pulse {
    0% {
        transform: translate(-50%, -50%) scale(1);
    }

    50% {
        transform: translate(-50%, -50%) scale(1.1);
    }

    100% {
        transform: translate(-50%, -50%) scale(1);
    }
}

@keyframes jackpotPulse {
    0% {
        transform: translate(-50%, -50%) scale(1);
        background-color: rgba(255, 0, 0, 0.8);
    }

    50% {
        transform: translate(-50%, -50%) scale(1.2);
        background-color: rgba(255, 215, 0, 0.8);
    }

    100% {
        transform: translate(-50%, -50%) scale(1);
        background-color: rgba(255, 0, 0, 0.8);
    }
}

.game-info {
    width: 100%;
    color: #999;
    font-size: 14px;
    text-align: center;
    margin: 10px 0;
}

.bet-info {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin-bottom: 10px;
}

.credit-display {
    font-size: 16px;
    color: #FFD700;
    font-weight: bold;
}

.euro-symbol {
    color: #FFD700;
    font-weight: bold;
}

.bet-amount {
    color: #FFD700;
    font-weight: bold;
}

.divider {
    margin: 0 5px;
}

.spins-left {
    color: #ccc;
}

.spin2-button {
    width: 100%;
    padding: 15px;
    background: linear-gradient(to bottom, #FFD700, #e6c200);
    border: none;
    border-radius: 5px;
    color: #000;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3), inset 0 -3px 0 rgba(0, 0, 0, 0.2), inset 3px 0 0 rgba(255, 255, 255, 0.2);
    text-transform: uppercase;
    position: relative;
    overflow: hidden;
}

.spin2-button::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 10px;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
}

.spin2-button:hover {
    background: linear-gradient(to bottom, #FFE44D, #FFD700);
}

.spin2-button:disabled {
    background: linear-gradient(to bottom, #aaa, #888);
    cursor: not-allowed;
}

.reset-button {
    margin-top: 15px;
    padding: 10px 20px;
    background-color: #444;
    border: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
}

.reset-button:hover {
    background-color: #555;
}

.back-button{
    position: absolute;
    top: 0;
    left: 20px;
    color: white;
    font-size: 24px;
    z-index: 343;
}