/* General styling */
.airdrop {
  display: flex;
  flex-direction: column;
  padding: 20px;
  color: #ffffff;
}

h1,
h2 {
  text-align: center;
}

/* Roadmap container */
.roadmap-container {
  height: 80vh;
  padding: 20px 0;
}

.roadmap-title {
  color: #cdeb45;
  font-size: 30px;
  margin-bottom: 10px;
}

/* Mascot styling */
.mascot {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.mascot-img {
  width: 150px;
  height: 150px;
}

/* Roadmap image styling */
.roadmap-img {
  width: 100vw;
  height: auto;
  object-fit: contain;
  object-position: top;
}

/* Remove unused roadmap-specific CSS */
