.lb-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: black;
}

.lb-total-container {
  width: 100%;
  display: flex;
  padding: 6px 20px;
  align-items: center;
  justify-content: space-between;
}

.lb-total-text {
  font-size: 16px;
  color: #cdeb45;
  font-weight: normal;
}

.header {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: black;
}

.back-button {
  background: none;
  border: none;
  cursor: pointer;
}

.title {
  flex-grow: 1;
  text-align: center;
  font-size: 18px;
  color: #745e50;
}

.lb-image {
  width: 160px;
  height: 160px;
}

.lb-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  margin: auto;
}

.lb-image-container {
  margin-bottom: 5px;
  margin-top: 20px;
}

.reward-text,
.bonus-text {
  text-align: center;
  margin: 5px 0;
  color: white;
}

.bonus-text {
  font-size: 0.9em;
  color: #a67c52;
}

.lb-list-container {
  width: 100%;
}

.lb-container h2 {
  font-size: 16px;
  color: #cdeb45;
  margin-bottom: 10px;
}

.left-div,
.right-div {
  padding: 10px;
  background-color: lightgray;
  border: 1px solid #ccc;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.lb-list {
  list-style: none;
  margin-bottom: 0px;
  padding: 10px 20px;
}

.user-stat-container {
  display: flex;
  align-items: center;
  background-color: #c6f726;
  color: black;
  font-weight: bold;
  border-radius: 10px;
  padding: 8px;
  margin: auto;
  width: 98%;
  margin-bottom: 10px;
}

.lb-rank {
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  margin-right: 16px;
}

.current {
  font-size: 20px;
  color: #000;
}

.lb-item {
  display: flex;
  align-items: center;
  background-color: white;
  background-color: #5b5b5b;
  font-weight: bold;
  border-radius: 10px;
  padding: 6px;
  padding-right: 10px;
  margin-bottom: 10px;
}

.lb--current-avatar {
  width: 60px;
  height: 60px;
  background-color: #535151;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  font-weight: bold;
  font-size: 24px;
  color: #fff;
}

.lb-avatar {
  width: 50px;
  height: 50px;
  background-color: #535151;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  font-weight: bold;
  color: white;
  font-size: 20px;
}

.lb-name-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 2px;
}

.lb-current-tokens {
  color: black;
  display: flex;
  gap: 4px;
}

.lb-current-name {
  color: black;
  font-family: "myriadpro-bold";
  font-size: 18px;
}

.lb-name {
  flex-grow: 1;
  color: #fff;
  font-size: 16px;
}

.lb-tokens {
  gap: 6px;
  color: #fff;
  display: flex;
  font-weight: bold;
  align-items: center;
}

.invite-button {
  height: 30px;
  background-color: #cdeb45;
  color: black;
  border: none;
  border-radius: 20px;
  padding: 15px 30px;
  font-size: 16px;
  font-weight: bold;
  margin-top: 20px;
  cursor: pointer;
}

/* .invite-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 25px;
  width:100%;
} */

.invite-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 25px;
  width: 90%;
  position: fixed;
  /* Fixed position relative to the viewport */
  bottom: 0px;
  /* Dock to the bottom of the viewport */
  z-index: 100;
  /* Ensure it's above other elements */
}

.invite-button {
  height: 30px;
  background-color: #cdeb45;
  color: black;
  padding: 10px 20px;
  border: none;
  border-radius: 12px;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  margin: 0;
  margin-right: 10px;
  flex-grow: 1;
  text-align: center;
  animation: pulseButton 1s infinite ease-in-out;
}

.countdown {
  text-align: center;
  margin-bottom: 4px;
  color: white;
  font-size: larger;
  color: #cdeb45;
}

.copy-button {
  background-color: #cdeb45;
  border: none;
  border-radius: 20%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.copy-button svg {
  width: 24px;
  height: 24px;
  color: white;
}

.invite-button:hover,
.copy-button:hover {
  opacity: 0.9;
}

.invite-button:active,
.copy-button:active {
  opacity: 0.8;
}

@keyframes pulseButton {
  0% {
    width: 200px;
    font-size: 16px;
  }

  50% {
    width: 205px;
    font-size: 18px;
  }

  100% {
    width: 200px;
    font-size: 16px;
  }
}

.animated-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100px;
  height: 40px;
  animation: pulseButton 1s infinite ease-in-out;
}

.invite-buddy-button {
  height: 50px;
  font-size: 16px;
  background-color: #cdeb45;
  color: black;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  font-weight: bold;
  text-decoration: none;
  margin: 0;
  margin-right: 10px;
  flex-grow: 1;
  text-align: center;
  animation: inviteButton 1s infinite ease-in-out;
}

@keyframes inviteButton {
  0% {
    width: 100px;
    font-size: 16px;
  }

  50% {
    width: 105px;
    font-size: 17px;
  }

  100% {
    width: 100px;
    font-size: 16px;
  }
}

.btn-container {
  width: 90%; /* Cover all available width of the parent container */
  display: flex; /* Use flexbox for layout */
  justify-content: space-between; /* Space between the buttons */
  height: 50px;
  bottom: 15px;
  position: fixed;
  padding-right: 10px;
  padding-left: 20px;
}

.btn1 {
  flex: 0 0 75%; /* Initial flex-basis of 60% */
  font-size: 16px; /* Initial font size */
  animation: animateBtn1 1s ease-in-out infinite;
  transform-origin: center; /* Set the origin to center for scaling */
  border-radius: 10px;
  border: none;
  background-color: #cdeb45;
  color: black;
  font-weight: bold;
}

.btn2 {
  width: 20%; /* Set width instead of flex */
  border-radius: 10px;
  border: none;
  background-color: #cdeb45;
  color: black;
  font-weight: bold;
}

@keyframes animateBtn1 {
  0% {
    transform: scaleX(1); /* No scaling, default width */
    font-size: 16px; /* Initial font size */
  }
  50% {
    transform: scaleX(1.05); /* Expand horizontally by 20% */
    font-size: 18px; /* Increase font size */
  }
  100% {
    transform: scaleX(1); /* Reset to original width */
    font-size: 16px; /* Reset font size */
  }
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #000; /* Pure Black */
}

/* Loader Animation */
.loader {
  width: 75px;
  height: 75px;
  border: 5px solid #cdeb45;
  border-top: 5px solid  #5b5b5b; /* White Color */
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

/* Keyframes for Rotation */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
